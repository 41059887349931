<script>
import { required } from 'vuelidate/lib/validators'
import Multiselect from "vue-multiselect";
import Repository from "@/app/repository/repository-factory";

const PaymentApplicationRepository = Repository.get("PaymentApplicationRepository");
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");

export default {
  name: 'AccountNumberComponent',
  components: { Multiselect },
  props: {
    index: {
      type: Number,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    incomingAccountNumber: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      accountNumber: '',
      accountsList: [],
      inputLoading: false,
    }
  },
  validations: {
    accountNumber: {
      required,
    },
  },
  created() {
    this.getData();
    if (this.incomingAccountNumber) {
      this.accountNumber = this.incomingAccountNumber
    }
  },
  methods: {
    async getData() {
      this.inputLoading = true;
      try {

        const promise = await PaymentApplicationRepository.get(this.application.applicationId);
        const promise2 = await PaymentCompanyRepository.getBankAccounts(
          promise.data.companyId
        );

        await promise2.data.forEach(async function (item) {
          PaymentBankRepository.get(item.bankId)
            .then((response) => {
              item["bankName"] = response.data.name;
            })
            .catch((error) => {
              console.log(error);
            });
        });

        await Promise.all([promise, promise2]);
        this.accountsList = promise2.data;

        let account = this.accountsList.filter((obj) => {
          return obj.bankAccountNumber === promise.data.bankAccountNumber;
        });
        this.accountNumber = account[0];

        this.inputLoading = false;
      } catch (error) {
        console.log(error);
        this.inputLoading = false;
      }

    },
    accountLabel({bankAccountName, bankAccountNumber, bankAccountId, bankName}) {
      return `${bankAccountName} ${bankAccountNumber} ${bankAccountId}, ${bankName}`;
    },
  },
  watch: {
    incomingAccountNumber: function () {
      this.accountNumber = this.incomingAccountNumber
    },
    accountNumber: function () {
      this.$emit('account-number', this.accountNumber, this.index)
    },
  },
}
</script>

<template>
  <div class="form-group" :class="{ 'is-invalid': $v.accountNumber.$error }" v-loading="inputLoading">
    <label for="accountNumber">
      Wybierz nr rachunku
      <em class="text-danger">*</em>
    </label>
    <multiselect
      id="accountNumber"
      v-model.trim="accountNumber"
      :options="accountsList"
      :custom-label="accountLabel"
      track-by="bankAccountNumber"
      @input="$v.accountNumber.$touch()"
      :class="{
        'is-invalid': $v.accountNumber.$error,
        'is-valid': !$v.accountNumber.$invalid && !$v.accountNumber.$error,
      }"
      name="accountNumber"
      placeholder="Wybierz rachunek z listy"
      select-label
      :allowEmpty="false"
      deselect-label="Odznacz ✕"
      selected-label="Wybrane ✓"
      value
      required
      :disabled="inputLoading"
    >
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.bankAccountName }}</span>
          <br />
          <span class="option__small small text-dark">
            {{ props.option.bankName }} {{ props.option.bankAccountNumber }}
          </span>
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.bankAccountName }}</span>
          <br />
          <span class="option__small small text-dark">
            {{ props.option.bankName }} {{ props.option.bankAccountNumber }}
          </span>
        </div>
      </template>
      <template slot="clear">
        <div class="multiselect__loading">
          <i v-if="inputLoading" class="el-icon-loading mr-1"></i>
        </div>
      </template>
      <template slot="noOptions">Lista jest pusta.</template>
      <template slot="noResult">Nie znaleziono elementów.</template>
    </multiselect>
    <template v-if="$v.accountNumber.$error">
      <small class="text-danger">Pole jest wymagane!</small>
    </template>
  </div>
</template>
