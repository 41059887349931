<script>
import { required } from 'vuelidate/lib/validators'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl'
import Repository from "@/app/repository/repository-factory";
import { dateShortcutsPoint } from "@/components/data/dateShortcuts";

const PaymentApplicationRepository = Repository.get("PaymentApplicationRepository");

export default {
  name: 'DateOfOperationComponent',
  components: { DatePicker },
  props: {
    index: {
      type: Number,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    incomingDateOfOperation: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dateOfOperation: '',
      inputLoading: false,
      dateShortcutsPoint: dateShortcutsPoint,
    }
  },
  validations: {
    dateOfOperation: {
      required,
    },
  },
  created() {
    this.getData();
    if (this.incomingDateOfOperation) {
      this.dateOfOperation = this.incomingDateOfOperation
    }
  },
  methods: {
    async getData() {
      this.inputLoading = true;
      try {
        const promise = await PaymentApplicationRepository.get(this.application.applicationId);
        await Promise.all([promise]);
        this.dateOfOperation = promise.data.dateOfOperation;
        this.inputLoading = false;
      } catch (error) {
        console.log(error);
        this.inputLoading = false;
      }

    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < today
    },
  },
  watch: {
    incomingDateOfOperation: function () {
      this.dateOfOperation = this.incomingDateOfOperation
    },
    dateOfOperation: function () {
      this.$emit('date-of-operation', this.dateOfOperation, this.index)
    },
  },
}
</script>

<template>
  <div class="form-group" v-loading="inputLoading">
    <label for="dateOfOperation">
      Data operacji
      <em class="text-danger">*</em>
    </label>
    <date-picker
      id="dateOfOperation"
      name="dateOfOperation"
      v-model="dateOfOperation"
      :shortcuts="dateShortcutsPoint"
      value-type="format"
      format="YYYY-MM-DD"
      :editable="false"
      :clearable="false"
      :first-day-of-week="1"
      :default-value="new Date()"
      :disabled-date="disabledBeforeToday"
      @input="$v.dateOfOperation.$touch()"
      :class="{
        'is-invalid': $v.dateOfOperation.$error,
        'is-valid': !$v.dateOfOperation.$invalid && !$v.dateOfOperation.$error,
      }"
      lang="pl"
      placeholder="Data w formacie YYYY-MM-DD"
      :disabled="inputLoading"
    />
    <div v-if="$v.dateOfOperation.$invalid" class="invalid-feedback">
      <span class="d-block" v-if="$v.dateOfOperation.$invalid && !$v.dateOfOperation.required">Pole jest wymagane!</span>
    </div>
  </div>
</template>
