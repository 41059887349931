<script>
import Layout from "@/router/layouts/main";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import Repository from "@/app/repository/repository-factory";
import { typeOfApplicationStatus, typeOfApplications } from "@/data/data-type-of-application-status";
import { appService } from "@/app/service/appService";
import Vue from "vue";
import { roleService } from "@/app/service/roleService";
import { dateShortcutsRange } from "@/components/data/dateShortcuts";
import gridSetup from "@/components/data/gridSetup";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import DateOfOperation from "@/components/drawer/date-of-operation.vue";
import AccountNumber from "@/components/drawer/account-number.vue";
import Dropzone from "@/components/drawer/dropzone.vue";

const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");

/**
 * Account List
 */
export default {
  page: {
    title: "Lista wniosków",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, Multiselect, DatePicker, DateOfOperation, AccountNumber, Dropzone },
  data() {
    return {
      ...gridSetup.data(),
      header: {
        title: "Wnioski",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista wniosków",
          },
        ],
      },
      fieldsSelectOptions: [],
      reportArray: [],
      isBusy: false,
      rf: {
        "Numer": "applicationNumber",
        "Typ": "typeOfApplication",
        "Rodzaj przelewu": "typeOfTransfer",
        "Status": "applicationStatus",
        "Wnioskujący": "applicantName",
        "Akceptujący": "acceptorName",
        "Data akceptacji": "acceptorDate",
        "Tytuł": "transfer",
        "Kwota": {
          field: "grossAmount",
          callback: (value) => {
            return value.replace(".", ",");
          },
        },
        "Waluta": "currency",
        "Dostawca": "providerName",
        "Nip dostawcy": "providerTaxNumber",
        "Nr rachunku dostawcy": "providerBankAccountNumber",
        "Firma": "companyBusinessUnitCode",
        "Bank": "bankShortName",
        "Typ konta": "typeOfBankAccount",
        "Data operacji": "dateOfOperation",
        "Data utworzenia": "createdAt",
      },
      companySelectOptions: [],
      bankSelectOptions: [],
      typeOfApplicationSelectOptions: typeOfApplications,
      statusSelectOptions: typeOfApplicationStatus,
      barvalue: 0,
      selectOrDeselectAll: false,
      selectedItems: [],
      apiRange: '',
      drawer: false,
      accountsList: [],
      inputLoading: false,
      disabledBtn: false,
      preloader: true,
      totalSelectedRows: 0,
      totalRowsGrossAmount: 0,
      detailsEditForm: {
        dateOfOperation: [],
        accountNumber: [],
        attachments: [],
      },
      timeOne: this.setTimeClock(),
      dateShortcutsRange: dateShortcutsRange,
      componentKey: 0,
    };
  },
  created() {
    if (
      this.isProductAdministrator &&
      !this.isAdmin &&
      !this.isSuperAccepting &&
      !this.isFinancialOperator
    ) {
      this.$router.push({ name: "Lista rachunków bankowych" });
    } else {
      this.getStateGridFilters();
      this.getCompanies();
      this.getBank();
    }
  },
  mounted: function () {
    setInterval(() => {
      this.timeOne = this.setTimeClock();
    }, 1000);
  },
  methods: {
    compareUserToAcceptor(user) {
      return user === this.$store.getters["auth/loggedIn"].userAccountId;
    },
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a);
    },
    accountLabel({
      bankAccountName,
      bankAccountNumber,
      bankAccountId,
      bankName,
    }) {
      return `${bankAccountName} ${bankAccountNumber} ${bankAccountId}, ${bankName}`;
    },
    onCompleteMultiAccount: async function () {

      this.formSubmitMultiAccount();

      for (var j = 0; j < this.selectedItems.length; j++) {
        if(this.checkStatus(this.selectedItems[j].applicationStatus, [3, 5, 6]) && this.$refs.DateOfOperation[j] && this.$refs.AccountNumber[j]) {
          if (
            this.$refs.DateOfOperation[j].$v.dateOfOperation.$error === true ||
            this.$refs.AccountNumber[j].$v.accountNumber.$error
          ) {
            return false;
          }
        }
      }

      var tempItems = this.selectedItems;

      try {
        this.barvalue = 0.01;
        this.drawer = false;

        for (var i=0; i < this.selectedItems.length; i++) {
          if(this.checkStatus(this.selectedItems[i].applicationStatus, [3, 5, 6])) {
            let applicationData = {
              bankAccountId: this.detailsEditForm.accountNumber[i].bankAccountId,
              attachments: this.detailsEditForm.attachments[i],
              version: this.selectedItems[i].version,
            };

            if (this.selectedItems[i].dateOfOperation !== this.detailsEditForm.dateOfOperation[i]) {
              applicationData['dateOfOperation'] = this.detailsEditForm.dateOfOperation[i];
            }

            const payAppData = await PaymentApplicationRepository.updateItem(
              applicationData,
              this.selectedItems[i].applicationId
            )
              .then(() => {
                this.$notify({
                  title: "Sukces!",
                  dangerouslyUseHTMLString: true,
                  message: "Dane wniosku " + this.selectedItems[i].applicationNumber +" zostały zmienione!",
                  type: "success",
                  duration: 2500,
                  position: "top-right",
                });
              })
              .catch((error) => {
                this.$notify({
                  title: "Błąd!",
                  dangerouslyUseHTMLString: true,
                  message: "Wystąpił błąd z " + this.selectedItems[i].applicationNumber + "<br/>" + error.response.data.message,
                  type: "error",
                  duration: 2500,
                  position: "top-right",
                });
              });
            await Promise.all([payAppData]);
            await this.nextProgression(this.selectedItems.length);
          }
        }
        this.$refs.table_application.refresh();
      } catch (error) {
        console.log(error);
      }
      this.selectedItems = [];
      setTimeout(async () => {
        this.barvalue = 0;
      }, 500);
    },
    formSubmitMultiAccount(e) {
      for (var i = 0; i < this.selectedItems.length; i++) {
        if(this.checkStatus(this.selectedItems[i].applicationStatus, [3, 5, 6]) && this.$refs.DateOfOperation[i] && this.$refs.AccountNumber[i]) {
          this.$refs.DateOfOperation[i].$v.$touch();
          this.$refs.AccountNumber[i].$v.$touch();
          this.$refs.Dropzone[i].$v.$touch();
        }
      }
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    changeStatusToRealised: async function (statusNumber) {
      this.$refs.dropdown.hide(true);
      this.setSelectedItems();
      if(this.selectedItems.length > 0) {
        try {
          this.barvalue = 0.01;

          for (var i=0; i < this.selectedItems.length; i++) {
            if(this.checkStatus(this.selectedItems[i].applicationStatus, [3, 5])) {
              let applicationData = {
                applicationStatus: statusNumber,
                version: this.selectedItems[i].version,
              };

              const payAppData = await PaymentApplicationRepository.updateItem(
                applicationData,
                this.selectedItems[i].applicationId
              )
                .then(() => {
                  // this.$notify({
                  //   title: "Sukces!",
                  //   dangerouslyUseHTMLString: true,
                  //   message: "Dane wniosków zostały zmienione!",
                  //   type: "success",
                  //   duration: 2500,
                  //   position: "top-right",
                  // });
                })
                .catch((error) => {
                  this.$notify({
                    title: "Błąd!",
                    dangerouslyUseHTMLString: true,
                    message: "Wystąpił błąd z " + "<br/>" + error.response.data.message,
                    type: "error",
                    duration: 2500,
                    position: "top-right",
                  });
                });
              await Promise.all([payAppData]);
              await this.nextProgression(this.selectedItems.length);
            }
          }
          this.$refs.table_application.refresh();
        } catch (error) {
          console.log(error);
        }
        this.selectedItems = [];
        setTimeout(async () => {
          this.barvalue = 0;
        }, 500);
      } else {
        this.$notify({
          title: "Błąd!",
          dangerouslyUseHTMLString: false,
          message: "Brak zaznaczonych wniosków!",
          type: "error",
          duration: 2500,
          position: "top-right",
        });
      }
    },
    // onActionClick(row) {
    //   this.$router.push({
    //     name: "applications-details",
    //     params: { uuid: row.item.applicationId },
    //   });
    // },
    authorizeProviderChange: async function (statusNumber) {
      this.$refs.dropdown.hide(true);
      this.setSelectedItems();
      if(this.selectedItems.length > 0) {
        try {
          this.barvalue = 0.01;

          for (var i=0; i < this.selectedItems.length; i++) {
            if(
              this.checkStatus(this.selectedItems[i].applicationStatus, [1]) &&
              this.selectedItems[i].changeDataManually == 1 &&
              this.compareUserToAcceptor(this.selectedItems[i].higherAcceptorUserAccountId))
            {
              let applicationData = {
                applicationStatus: statusNumber,
                version: this.selectedItems[i].version,
              };

              const payAppData = await PaymentApplicationRepository.updateItem(
                applicationData,
                this.selectedItems[i].applicationId
              )
                .then(() => {
                  // this.$notify({
                  //   title: "Sukces!",
                  //   dangerouslyUseHTMLString: true,
                  //   message: "Dokonano autoryzacji danych dostawcy!",
                  //   type: "success",
                  //   duration: 2500,
                  //   position: "top-right",
                  // });
                })
                .catch((error) => {
                  this.$notify({
                    title: "Błąd!",
                    dangerouslyUseHTMLString: true,
                    message: "Wystąpił błąd z " + "<br/>" + error.response.data.message,
                    type: "error",
                    duration: 2500,
                    position: "top-right",
                  });
                });
              await Promise.all([payAppData]);
              await this.nextProgression(this.selectedItems.length);
            }
          }
          this.$refs.table_application.refresh();
        } catch (error) {
          console.log(error);
        }
        this.selectedItems = [];
        setTimeout(async () => {
          this.barvalue = 0;
        }, 500);
      } else {
        this.$notify({
          title: "Błąd!",
          dangerouslyUseHTMLString: false,
          message: "Brak zaznaczonych wniosków!",
          type: "error",
          duration: 2500,
          position: "top-right",
        });
      }
    },
    changeStatus: async function (statusNumber) {

      this.$refs.dropdown.hide(true);
      this.setSelectedItems();

      if(this.selectedItems.length > 0) {
        this.barvalue = 0.01;

        Swal.fire({
          title: "Podaj przyczynę zmiany statusu wniosków",
          input: "text",
          showCancelButton: true,
          confirmButtonText: "Zmień status",
          cancelButtonText: "Anuluj",
          showLoaderOnConfirm: true,
          confirmButtonColor: "#61aa3d",
          cancelButtonColor: "#e2001a",
          inputValidator: (reason) => {
            if (!reason) {
              return "Podaj przyczynę!";
            }
          },
          preConfirm: async (reason) => {
            try {
              for (var i=0; i < this.selectedItems.length; i++) {
                if(this.checkStatus(this.selectedItems[i].applicationStatus, [3, 5])) {
                  let applicationData = {
                    applicationStatus: statusNumber,
                    version: this.selectedItems[i].version,
                    statusMessage: reason,
                  };

                  const payAppData = await PaymentApplicationRepository.updateItem(
                    applicationData,
                    this.selectedItems[i].applicationId
                  )
                    .then(() => {
                      // this.$notify({
                      //   title: "Sukces!",
                      //   dangerouslyUseHTMLString: true,
                      //   message: "Dane wniosków zostały zmienione!",
                      //   type: "success",
                      //   duration: 2500,
                      //   position: "top-right",
                      // });
                    })
                    .catch((error) => {
                      this.$notify({
                        title: "Błąd!",
                        dangerouslyUseHTMLString: true,
                        message: "Wystąpił błąd z " + "<br/>" + error.response.data.message,
                        type: "error",
                        duration: 2500,
                        position: "top-right",
                      });
                    });
                  await Promise.all([payAppData]);
                  await this.nextProgression(this.selectedItems.length);
                }
              }
              this.$refs.table_application.refresh();
            } catch (error) {
              console.log(error);
            }
            this.selectedItems = [];
            setTimeout(async () => {
              this.barvalue = 0;
            }, 500);
          },
          allowOutsideClick: false,
        }).then((reason) => {
          if (reason.value) {
            // "SUKCES!", "Status wniosku został zmieniony!", "success";
          } else if (reason.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              "Anulowano",
              "Status wniosków nie został zmieniony!",
              "error"
            );
          }
        });
      } else {
        this.$notify({
          title: "Błąd!",
          dangerouslyUseHTMLString: false,
          message: "Brak zaznaczonych wniosków!",
          type: "error",
          duration: 2500,
          position: "top-right",
        });
      }
    },
    fieldsComputed(fieldList) {
      let fieldsComputed = [];
      fieldsComputed.push({
        key: "selected",
        label: "#",
      });
      let fieldsSelected = [];
      const selectedCount = this.filters.grid.fieldsSelected.length;
      const defaultField = [
        "applicationNumber",
        "typeOfApplication",
        "nameOfRecipient",
        "applicant",
        "transfer",
        "companyBusinessUnitCode",
        "createdAt",
      ];

      // If selected is empty (eg. no save state)
      if (selectedCount === 0) {
        Object.entries(fieldList).forEach(([key, val]) => {
          if (defaultField.includes(val.key)) {
            fieldsSelected.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });

            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        });

        this.filters.grid.fieldsSelected = fieldsSelected;
      }

      // If selected is not empty
      if (selectedCount > 0) {
        Object.entries(this.filters.grid.fieldsSelected).forEach(
          ([key, val]) => {
            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        );
      }

      fieldsComputed.push({ key: "action", label: "Akcje", thClass: "last-col-sticky", tdClass: "last-col-sticky" });

      this.grid.fields = fieldsComputed;
    },
    getCompanies() {
      PaymentCompanyRepository.getAll()
        .then((response) => {
          let companies = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            companies.push({
              text: response.data[i].name,
              value: response.data[i].businessUnitCode,
            });
          }

          this.companySelectOptions = companies;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBank() {
      PaymentBankRepository.getAll()
        .then((response) => {
          let bank = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            bank.push({
              text: response.data[i].name,
              value: response.data[i].shortName,
            });
          }

          this.bankSelectOptions = bank;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gridProvider(ctx) {
      // if (localStorage.getItem("gridApplicationPaginationState") !== null) {
      //   ctx.currentPage = JSON.parse(
      //     localStorage.getItem("gridApplicationPaginationState")
      //   );
      // }

      // if (localStorage.getItem("gridApplicationSearchState") !== null) {
      //   this.filters.grid.filter = JSON.parse(
      //     localStorage.getItem("gridApplicationSearchState")
      //   );
      // }

      if (this.filters.dateRange.length > 0) {
        this.apiRange =
          "&startDate=" +
          this.filters.dateRange[0] +
          "&endDate=" +
          this.filters.dateRange[1];
      } else {
        this.apiRange = "";
      }

      const parameters =
        "?page=" +
        ctx.currentPage +
        "&maxItems=" +
        ctx.perPage +
        "&search=" +
        this.filters.grid.filter +
        "&orderBy=" +
        this.filters.grid.sortBy +
        "&orderDesc=" +
        this.filters.grid.sortDesc +
        this.apiRange +
        "&filterTypeOfApplication=" +
        this.filters.typeOfApplication.map((e) => e.value).join(",") +
        "&filterStatus=" +
        this.filters.status.map((e) => e.value).join(",") +
        "&filterBank=" +
        this.filters.bank.map((e) => e.value).join(",") +
        "&filterCompany=" +
        this.filters.companies.map((e) => e.value).join(",");
      const promise = PaymentApplicationRepository.getGrid(parameters);

      return promise
        .then((data) => {
          const fields = data.data.info.fields;
          this.grid.totalRows = data.data.info.countAllItems;

          // if (localStorage.getItem("gridApplicationPaginationState") !== null) {
          //   this.grid.currentPage = JSON.parse(
          //     localStorage.getItem("gridApplicationPaginationState")
          //   );
          // }

          let fieldsToSelect = [];

          Object.entries(fields).forEach(([key, val]) => {
            if (val.disabled !== true) {
              fieldsToSelect.push({
                text: val.label,
                value: key,
                label: val.label,
                key: key,
                sortable: val.sortable,
              });
            }
          });

          this.fieldsSelectOptions = fieldsToSelect;
          this.fieldsComputed(fieldsToSelect);

          this.reportArray = data.data.data || [];

          return this.reportArray;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    refreshGrid(value, id) {
      this.$refs.table_application.refresh();
    },
    clearGridFiltersAndCols() {
      localStorage.removeItem("gridApplicationPaginationState");
      localStorage.removeItem("gridApplicationSearchState");
      this.grid.currentPage = 1;
      this.filters.status = [];
      this.filters.companies = [];
      this.filters.typeOfApplication = [];
      this.filters.bank = [];
      this.filters.dateRange = [];
      this.filters.grid.filter = "";
      this.filters.grid.filterOn = [""];
      this.filters.grid.perPage = 25;
      this.filters.grid.sortBy = "createdAt";
      this.filters.grid.sortDesc = true;
      this.filters.grid.fieldsSelected = [];
      this.saveStateGridFilters();
    },
    clearGridFilters() {
      this.grid.currentPage = 1;
      this.filters.status = [];
      this.filters.companies = [];
      this.filters.typeOfApplication = [];
      this.filters.bank = [];
      this.filters.dateRange = [];
      this.$refs.table_application.refresh();
    },
    saveStateGridFilters() {
      localStorage.setItem(
        "gridApplicationState",
        JSON.stringify(this.filters)
      );
      this.$refs.table_application.refresh();

      Vue.swal({
        icon: "success",
        toast: false,
        position: "top",
        title: "SUKCES!",
        text: "Filtry zostały zapisane!",
        showConfirmButton: false,
        timer: 3500,
      });
    },
    getStateGridFilters() {
      if (localStorage.getItem("gridApplicationState") !== null) {
        this.filters = JSON.parse(localStorage.getItem("gridApplicationState"));
      }
    },
    clearAllStatus() {
      this.filters.status = [];
      this.$refs.table_application.refresh();
    },
    clearAllCompanies() {
      this.filters.companies = [];
      this.$refs.table_application.refresh();
    },
    clearAllTypeOfApplication() {
      this.filters.typeOfApplication = [];
      this.$refs.table_application.refresh();
    },
    clearAllBank() {
      this.filters.bank = [];
      this.$refs.table_application.refresh();
    },
    clearAllDateRange() {
      this.filters.dateRange = [];
      this.$refs.table_application.refresh();
    },
    checkColor(s) {
      return appService.checkAppColor(s);
    },
    setTimeClock() {
      return appService.getTimeClock();
    },
    async openDrawer() {
      this.forceRerender();
      this.drawer = true;
      this.$refs.dropdown.hide(true);
      this.setSelectedItems();
    },
    setSelectedItems() {
      this.selectedItems = this.getGridSelectedItems();
    },
    getGridSelectedItems() {
      let si = [];
      if (localStorage.getItem("sipGridSelectedItems") !== null) {
        si = JSON.parse(localStorage.getItem("sipGridSelectedItems"));
      }
      return si
    },
    getGridTableRows() {
      let myTable = this.$refs.table_application.$el,
        tableBody = myTable.getElementsByTagName('tbody')[0],
        tableRows = tableBody.getElementsByTagName('tr')
      return tableRows
    },
    removeAllSelected() {
      this.totalSelectedRows = 0;
      this.selectOrDeselectAll = false;
      localStorage.removeItem("sipGridSelectedItems");
      this.totalRowsGrossAmount = this.totalRowsGrossAmountCount();
      this.$refs.table_application.refresh();
    },
    clickSelectOrDeselectAll() {

      let tableRows = this.getGridTableRows();

      if (this.selectOrDeselectAll) {
        setTimeout(async () => {
          for (var x=0; x < tableRows.length; x++) {
            if(tableRows[x]){
              if (tableRows[x].getAttribute("aria-selected") == 'false') {
                tableRows[x].click();
                tableRows[x].setAttribute('aria-selected', true)
              }
            }
          }
        }, 100);
      } else {
        setTimeout(async () => {
          for (var y=0; y < tableRows.length; y++) {
            if(tableRows[y]){
              if (tableRows[y].getAttribute("aria-selected") == 'true') {
                tableRows[y].click();
                tableRows[y].setAttribute('aria-selected', false)
              }
            }
          }
        }, 100);

      }
    },
    onRowSelected(items) {
    },
    onRowClicked(item) {

      let itemFound = false;
      let itemsSelectedArr = this.getGridSelectedItems();

      if (item.selectedItem == null || item.selectedItem == false) {

        this.$set(item, 'selectedIndex', item.applicationId)
        this.$set(item, 'selectedItem', true)

        for (var j=0; j < itemsSelectedArr.length; j++) {
          var x = itemsSelectedArr[j].applicationId;
          if(x == item.applicationId){
            itemFound = true;
            break
          }
        }

        if (!itemFound) {
          itemsSelectedArr.push(item)
        }

      } else {

        Object.entries(itemsSelectedArr).forEach(([key, val]) => {
          if(val.applicationId == item.applicationId){
            itemsSelectedArr.splice(key,1);
          }
        });

        this.$delete(item, 'selectedIndex')
        this.$set(item, 'selectedItem', false)
      }

      localStorage.setItem("sipGridSelectedItems", JSON.stringify(itemsSelectedArr));
      this.totalSelectedRows = itemsSelectedArr.length;
      this.totalRowsGrossAmount = this.totalRowsGrossAmountCount();
      // check this later
      // this.selectOrDeselectAll = this.isAllRowSelected

    },
    onToggleRow(data) {

      let itemFound = false;
      let itemsSelectedArr = this.getGridSelectedItems();
      let tableRows = this.getGridTableRows();

      if (data.item.selectedItem == true) {

        this.$set(data.item, 'selectedIndex', data.item.applicationId)
        this.$set(data.item, 'selectedItem', true)

        for (var j=0; j < itemsSelectedArr.length; j++) {
          var x = itemsSelectedArr[j].applicationId;
          if(x == data.item.applicationId){
            itemFound = true;
            break
          }
        }

        if (!itemFound) {
          itemsSelectedArr.push(data.item)
        }

        this.$set(data, 'rowSelected', true)

        setTimeout(async () => {
          for (var x=0; x < tableRows.length; x++) {
            if (tableRows[x].dataset.pk == data.item.applicationId ) {
              if(tableRows[x]){
                tableRows[x].setAttribute('aria-selected', true)
                break;
              }
            }
          }
        }, 100);

      } else {

        Object.entries(itemsSelectedArr).forEach(([key, val]) => {
          if(val.applicationId == data.item.applicationId){
            itemsSelectedArr.splice(key,1);
          }
        });

        this.$delete(data.item, 'selectedIndex')
        this.$set(data.item, 'selectedItem', false)
        this.$set(data, 'rowSelected', false)
        this.$refs.table_application.unselectRow(data.index)

        setTimeout(async () => {
          for (var x=0; x < tableRows.length; x++) {
            if (tableRows[x].dataset.pk == data.item.applicationId ) {
              if(tableRows[x]){
                tableRows[x].setAttribute('aria-selected', false)
                break;
              }
            }
          }
        }, 100);

      }

      localStorage.setItem("sipGridSelectedItems", JSON.stringify(itemsSelectedArr));
      this.totalSelectedRows = itemsSelectedArr.length;
      this.totalRowsGrossAmount = this.totalRowsGrossAmountCount();
      // check this later
      // this.selectOrDeselectAll = this.isAllRowSelected

    },
    onRefreshed() {

      let isEqual = false;
      let itemsSelectedArr = this.getGridSelectedItems();
      let tableRows = this.getGridTableRows();

      let totalRows = [];
      let totalSelectedRows = [];

      setTimeout(async () => {
        if (itemsSelectedArr && itemsSelectedArr.length > 0 ) {
          for (var x=0; x < tableRows.length; x++) {
            for (var y=0; y < itemsSelectedArr.length; y++) {
              if (tableRows[x].dataset.pk == itemsSelectedArr[y].applicationId ) {
                if(tableRows[x]){
                  tableRows[x].click();
                  tableRows[x].setAttribute('aria-selected', true)
                  break;
                }
              }
            }
          }
        }
      }, 100);

      setTimeout(async () => {

        for (var x=0; x < tableRows.length; x++) {
          totalRows.push(tableRows[x].dataset.pk)
        }

        Object.entries(itemsSelectedArr).forEach(([key, val]) => {
          totalSelectedRows.push(val.applicationId)
        });

        const includes = totalRows.filter(value => totalSelectedRows.includes(value)).filter((value, index, self) => self.indexOf(value) === index);
        isEqual = includes.sort().toString() == totalRows.sort().toString() ? true : false;

        this.selectOrDeselectAll = isEqual;

      }, 100);

    },
    tbodyRowClass(item) {
      if(item && item.selectedItem && item.selectedIndex){
        return ["b-table-row-selected", "table-active"]
      } else {
        return []
      }
    },
    totalRowsGrossAmountCount() {
      let si = this.getGridSelectedItems();
      let total = 0;
      for (let i = 0; i < si.length; i++) {
        total = total + parseFloat(si[i].grossAmount)
      }
      return total
    },
    async nextProgression(total) {
      this.barvalue += 100 / total;
    },
    async sendMultiGenerate() {
      this.$refs.dropdown.hide(true);
      this.setSelectedItems();
      try {
        if (this.selectedItems.length > 0) {
          this.barvalue = 0.01;
          //for (const si of this.selectedItems) {
          await PaymentApplicationRepository.generateBankFile(null, this.selectedItems)
            .then(() => {
              this.$notify({
                title: "Sukces!",
                dangerouslyUseHTMLString: true,
                message: "Plik dla wniosku " + "<br/>został wygenerowany!",
                type: "success",
                duration: 2500,
                position: "top-right",
              });
            })
            .catch((error) => {
              this.$notify({
                title: "Błąd!",
                dangerouslyUseHTMLString: true,
                message: "Wystąpił błąd z " + "<br/>" + error.response.data.message,
                type: "error",
                duration: 2500,
                position: "top-right",
              });
            });
          await this.nextProgression(this.selectedItems.length);
          //}

          this.$refs.table_application.refresh();
        } else {
          this.$notify({
            title: "Błąd!",
            dangerouslyUseHTMLString: false,
            message: "Brak zaznaczonych wniosków!",
            type: "error",
            duration: 2500,
            position: "top-right",
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.selectedItems = [];
      setTimeout(async () => {
        this.barvalue = 0;
      }, 500);
    },
    getDateOfOperation: function (params, index) {
      this.detailsEditForm.dateOfOperation[index] = params;
    },
    getAccountNumber: function (params, index) {
      this.detailsEditForm.accountNumber[index] = params;
    },
    getAttachments: function (params, index) {
      this.detailsEditForm.attachments[index] = params;
    },
    forceRerender() {
      this.componentKey += 1;
    }
  },
  computed: {
    totalFiltersLength() {
      let f1 = this.filters.status ? this.filters.status.length : 0;
      let f2 = this.filters.companies ? this.filters.companies.length : 0;
      let f3 = this.filters.typeOfApplication ? this.filters.typeOfApplication.length : 0;
      let f4 = this.filters.bank ? this.filters.bank.length : 0;
      let f5 = this.filters.dateRange && this.filters.dateRange.length > 0 ? 1 : 0
      return f1 + f2 + f3 + f4 + f5;
    },
    hasCorrectFields() {
      let result = false;
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.checkStatus(this.selectedItems[i].applicationStatus, [3, 5, 6])) {
          result = true;
          break;
        }
      }
      return result
    },
    totalSelectedOnPage() {
      let total = 0
      Object.entries(this.reportArray).forEach(([key, val]) => {
        if (val.selectedItem == true ) {
          total += 1
        }
      });
      return total;
    },
    isAllRowSelected() {
      return this.grid.totalRows == this.totalSelectedOnPage
    },
    isAdmin() {
      return roleService.isAdmin();
    },
    isProductAdministrator() {
      return roleService.isProductAdministrator();
    },
    isSuperAccepting() {
      return roleService.isSuperAccepting();
    },
    isFinancialOperator() {
      return roleService.isFinancialOperator();
    },
  },
  watch: {
    // "grid.currentPage": function () {
    //   localStorage.setItem(
    //     "gridApplicationPaginationState",
    //     JSON.stringify(this.grid.currentPage)
    //   );
    // },
    // "filters.grid.filter": function () {
    //   localStorage.setItem(
    //     "gridApplicationSearchState",
    //     JSON.stringify(this.filters.grid.filter)
    //   );
    // },
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Strona główna Administratora</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">Witaj w SI-PAY</li>
          </ol>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        {{ this.timeOne }}
      </div>
    </div>
    <!-- end page title -->

    <div class="row" v-if="isAdmin || isSuperAccepting || isFinancialOperator">
      <div class="col-12">
        <b-collapse id="collapse">
          <b-card class="shadow-sm">
            <div class="font-weight-bold" slot="header">Ustawienia kolumn i filtrów</div>
            <div class="form-group">
              <label class="typo__label">Wybierz widoczne kolumny tabeli</label>
              <multiselect v-model="filters.grid.fieldsSelected" :multiple="true" :options="fieldsSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz kolumny" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @input="fieldsComputed(fieldsSelectOptions)">
                <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
              </multiselect>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <div class="form-group">
                  <label class="typo__label">Zakres dat operacji</label>
                  <date-picker v-model="filters.dateRange" :shortcuts="dateShortcutsRange" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" lang="pl" placeholder="YYYY-MM-DD ~ YYYY-MM-DD" range @close="refreshGrid" @clear="clearAllDateRange" />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Rodzaj wniosku</label>
                  <multiselect v-model="filters.typeOfApplication" :multiple="true" :options="typeOfApplicationSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz typ wniosku" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych wniosków: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.typeOfApplication.length" @mousedown.prevent.stop="clearAllTypeOfApplication(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Status wniosku</label>
                  <multiselect v-model="filters.status" :multiple="true" :options="statusSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz status" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych statusów: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.status.length" @mousedown.prevent.stop="clearAllStatus(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Wybierz firmę</label>
                  <multiselect v-model="filters.companies" :multiple="true" :options="companySelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz firmę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych firm: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.companies.length" @mousedown.prevent.stop="clearAllCompanies(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Wybierz bank</label>
                  <multiselect v-model="filters.bank" :multiple="true" :options="bankSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz bank" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych banków: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.bank.length" @mousedown.prevent.stop="clearAllBank(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                <button type="button" class="btn btn-danger waves-effect waves-light mr-2" v-on:click="clearGridFiltersAndCols">
                  <i class="mdi mdi mdi-trash-can-outline mr-1" /> Usuń ustawienia
                </button>
                <button type="button" class="btn btn-dark waves-effect waves-light mr-2" v-on:click="saveStateGridFilters">
                  <i class="dripicons-pin mr-1" /> Zapisz ustawienia
                </button>
              </div>
              <div class="col text-right">
                <button type="button" class="btn btn-warning waves-effect waves-light" v-on:click="clearGridFilters">
                  <i class="mdi mdi-filter-remove-outline mr-1" /> Wyczyść filtry
                </button>
              </div>
            </div>
          </b-card>
        </b-collapse>
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <div>
                <span class="font-weight-bold">Wnioski</span>
                <template v-if="totalSelectedRows > 0" class="font-weight-normal">
                - Zaznaczono
                <span class="badge badge-pill badge-soft-info font-size-12 ml-1">{{ totalSelectedRows | wniosek }}</span>
                <span class="badge badge-pill badge-soft-success font-size-12 ml-1">{{ totalSelectedOnPage }} na stronie</span>
                <span class="badge badge-pill badge-soft-dark font-size-12 ml-1">na łączną kwotę {{ totalRowsGrossAmount | currencyFilter }}</span>
                <!-- <a v-b-tooltip.hover.topleft title="Odznacz wszystkie wnioski na wszystkich stronach">
                  <button @click="removeAllSelected" class="ml-1 btn btn-white text-danger m-0 p-0 font-size-16 mt-n2"><i class="mdi mdi-playlist-remove"></i></button>
                </a> -->
                </template>
              </div>
              <div>
                <i class="mdi mdi-format-columns mr-1"></i>Kolumny: <span class="badge badge-pill badge-soft-info font-size-12">{{ filters.grid.fieldsSelected.length + '/' + fieldsSelectOptions.length}}</span>
                <span class="ml-2" v-if="totalFiltersLength > 0"><i class="mdi mdi-filter-outline mr-1"></i>Aktywne filtry: <span class="badge badge-pill badge-soft-success font-size-12">{{ totalFiltersLength }}</span></span>
              </div>
            </div>
            <b-progress v-if="barvalue > 0" :value="barvalue" :max="100" variant="warning" animated class="mt-1"></b-progress>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <b-form inline>
                  <b-form-select v-model="filters.grid.perPage" :options="grid.pageOptions" class="w-auto" />
                  <div class="ml-2 search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filters.grid.filter" :debounce="350" placeholder="Szukaj..." :class="filters.grid.filter !='' ? 'border-success' : ''" />
                      <i class="ti-search search-icon" />
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="col-md-8">
                <div class="text-right mt-2 mt-md-0 mb-2">
                  <b-button class="waves-effect mb-2 mr-2" variant="primary" @click="refreshGrid">
                    <i class="mdi mdi-refresh mr-1"></i>Odśwież
                  </b-button>
                  <!-- <button type="button" v-if="isAdmin || isFinancialOperator" class="btn btn-dark waves-effect waves-light mb-2 mr-2" v-on:click="sendMultiGenerate">
                    <i class="mdi mdi-file-document-box-check-outline mr-1" />Generuj pliki
                  </button> -->

                  <download-excel class="btn btn-warning mb-2 mr-2" :stringifyLongNum="true" :data="reportArray" :fields="rf" worksheet="My Worksheet" name="filename.xls">
                    <i class="mdi mdi-file-excel mr-2"></i>Pobierz Excel
                  </download-excel>

                  <b-dropdown variant="success" right class="mb-2 mr-2" id="action-group-btn" ref="dropdown">
                    <template slot="button-content">
                      Grupowo
                      <i class="mdi mdi-chevron-down ml-1"></i>
                    </template>
                    <button type="button" v-if="isAdmin || isFinancialOperator" class="btn btn-dark btn-block" v-on:click="sendMultiGenerate">
                      <i class="mdi mdi-file-document-box-check-outline mr-1" />Generuj pliki
                    </button>
                    <button type="button" class="btn btn-warning btn-block mt-0" @click="openDrawer">
                      <i class="mdi mdi-file-document-box-check-outline mr-1" />Edytuj wniosek
                    </button>
                    <button type="button" v-if="isAdmin || isSuperAccepting" class="btn btn-secondary btn-block mt-0 mb-0" v-on:click="authorizeProviderChange(8)">
                      <i class="mdi mdi-file-document-box-check-outline mr-1" />Autoryzuj zmiany danych dostawcy
                    </button>
                    <button type="button" class="btn btn-success btn-block mt-0 mb-0" v-on:click="changeStatusToRealised(7)">
                      <i class="mdi mdi-file-document-box-check-outline mr-1" />Oznacz jako zrealizowany
                    </button>
                    <button type="button" class="btn btn-info btn-block mt-0 mb-0" v-on:click="changeStatusToRealised(13)">
                      <i class="mdi mdi-file-document-box-check-outline mr-1" />Oznacz jako ręczny
                    </button>
                    <button type="button" class="btn btn-danger btn-block mt-0 mb-0" v-on:click="changeStatus(6)">
                      <i class="mdi mdi-file-document-box-check-outline mr-1" />Oznacz jako odrzucony
                    </button>
                  </b-dropdown>

                  <!-- <button type="button" class="btn btn-dark waves-effect waves-light mb-2 mr-2" @click="openDrawer()" v-if="(isFinancialOperator || isAdmin) && hasCorrectFields && selectedItems.length > 0">
                    <i class="mdi mdi-file-document-box-check-outline mr-1" />Zmiana
                  </button> -->

                  <b-button v-b-toggle="'collapse'" class="waves-effect mb-2 collapsed" variant="success">
                    <i class="mdi mdi-filter-outline mr-1"></i>Filtry
                  </b-button>
                </div>
              </div>
            </div>

            <!-- Table -->
            <b-table :tbody-tr-class="tbodyRowClass" primary-key="applicationId" id="table_application" ref="table_application" selectable :items="gridProvider" :fields="grid.fields" :busy.sync="isBusy" responsive="sm" sticky-header="45vh" sort-icon-left :per-page="filters.grid.perPage" :current-page="grid.currentPage" :sort-by.sync="filters.grid.sortBy" :sort-desc.sync="filters.grid.sortDesc" :filter="filters.grid.filter" :filter-included-fields="filters.grid.filterOn" @row-selected="onRowSelected" @row-clicked="onRowClicked" @refreshed="onRefreshed">
              <template #head(selected)>
                <div class="d-flex justify-content-start">
                <a v-b-tooltip.hover.topright :title="!selectOrDeselectAll ? 'Zaznacz wszystkie wnioski na tej stronie' : 'Odznacz wszystkie wnioski na tej stronie'">
                  <b-form-checkbox v-model="selectOrDeselectAll" @change="clickSelectOrDeselectAll" class="on-toggle-row-checkbox" />
                </a>
                <a v-b-tooltip.hover.topright title="Odznacz wszystkie wnioski na wszystkich stronach">
                  <button @click="removeAllSelected" class="btn btn-white text-danger m-0 p-0 ml-1" ><i class="mdi mdi-playlist-remove"></i></button>
                </a>
                </div>
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-black">
                  <b-spinner class="align-middle" />
                  <strong class="ml-3">Ładowanie danych...</strong>
                </div>
              </template>
              <!-- <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.fix"
                    @input="onPreviewClick($event, row.index, row.item)"
                  />
                </template> -->

              <!-- <template #cell(selected)="data">
                    <b-form-checkbox v-model="data.rowSelected" @input="onToggleRow(data.index, data.rowSelected)" />
                </template> -->

              <!-- <template slot="selected" slot-scope="row">
                <b-form-checkbox v-model="row.item.check" @input="onPreviewClick($event, row.index, row.item)" />
              </template> -->

              <template v-slot:cell(selected)="data">
                <div class="d-flex justify-content-start">
                  <b-form-checkbox v-model="data.item.selectedItem" @change="onToggleRow(data)" class="on-toggle-row-checkbox" />
                  <template v-if="(isFinancialOperator || isAdmin) && data.item.countAttachments > 0">
                    <br />
                    <a v-b-tooltip.hover.right title="Dodano załączniki">
                      <i class="ion ion-md-attach text-success ml-1" style="font-size:18px"></i>
                    </a>
                  </template>
                </div>
              </template>

              <!-- <template v-slot:cell(confirmTransfer)="data">
                <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-danger': data.value == false, 'badge-soft-success': data.value == true }">
                  {{ data.value ? 'Tak' : 'Nie' }}</span>
              </template> -->

              <template data-simplebar v-slot:cell(grossAmount)="data">
                <span class="font-weight-bold float-right text-nowrap">{{ data.value | currencyFilter }}</span>
              </template>

              <template data-simplebar v-slot:cell(currency)="data">
                <span class="text-nowrap">
                  {{ data.value }}
                </span>
              </template>

              <template v-slot:cell(providerTaxNumber)="data">
                <span class="float-right">{{ data.value }}</span>
              </template>

              <!-- typeOf transfer in one column with type of application for internal transfer -->
              <template v-slot:cell(typeOfApplication)="row">
                <span class="text-nowrap">
                  {{ row.item.typeOfApplication }}
                  <template v-if="row.item.typeOfApplication == 'Przelew krajowy'">
                    <br />
                    <small class="text-muted">{{ row.item.typeOfTransfer }}</small>
                  </template>
                  <template v-if="row.item.typeOfApplication == 'Przelew skarbowy'">
                    <br />
                    <small class="text-muted">{{ row.item.applicationSpecificData.isRecurring ? 'Cykliczny' : 'Jednorazowy' }}</small>
                  </template>
                  <template v-if="row.item.typeOfApplication == 'Zasilenie karty'">
                    <br />
                    <small class="text-muted">{{ row.item.applicationSpecificData.prepaidCardSettlement.isChecked ? 'Rozliczenie sprawdzone' : 'Rozliczenie niesprawdzone' }}</small>
                  </template>
                </span>
              </template>

              <template v-slot:cell(applicationStatus)="row">
                <span class="badge font-size-12" :class="`badge-soft-${checkColor(row.item.applicationStatus)} ${row.item.acceptDateExceeded ? 'border border-warning' : ''}`">
                  <i v-if="row.item.acceptDateExceeded" class="mdi mdi-clock-alert-outline text-danger mr-2"></i>{{ row.item.applicationStatus }}
                </span>
                <template v-if="row.item.confirmTransfer == 1">
                  <br />
                  <!-- <i class="ion ion-md-attach mr-1" style="font-size:11px"></i> -->
                  <small class="text-muted text-nowrap">Wymagane potwierdzenie przelewu</small>
                </template>
                <!-- <template v-if="(isFinancialOperator || isAdmin) && row.item.countAttachments > 0">
                  <br />
                  <i class="ion ion-md-checkmark mr-1" style="font-size:11px"></i><small class="text-success text-nowrap">Dodano załączniki</small>
                </template> -->
              </template>

              <template v-slot:cell(createdAt)="row">
                <span class="text-nowrap">
                  <template v-if="row.item.acceptDateExceeded">
                    <a v-b-tooltip.hover.left title="Wniosek zaległy">
                      <i class="mdi mdi-clock-alert-outline text-danger mr-2"></i>
                      <span class="text-danger">{{ row.item.createdAt }}</span>
                    </a>
                  </template>
                  <template v-else>
                    {{ row.item.createdAt }}
                  </template>
                </span>
              </template>

              <template v-slot:cell(attachmentsNumber)="data">
                <span class="text-nowrap" v-if="data.value > 0">
                  <i class="ion ion-md-attach mr-1" style="font-size:16px"></i>
                </span>
              </template>

              <template v-slot:cell(acceptorDate)="data">
                <span class="text-nowrap">
                  {{ data.value }}
                </span>
              </template>

              <template v-slot:cell(action)="row">
                <ul class="list-inline font-size-20 contact-links mb-1">
                  <li class="list-inline-item px-2" v-if="isAdmin || isFinancialOperator">
                    <a v-b-tooltip.hover.topleft title="Szczegóły wniosku">
                      <b-link :to="{ name: 'applications-details', params: { uuid: row.item.applicationId } }" class="grid-action-btn">
                        <i class="font-size-14 far fa-file-alt text-info" />
                      </b-link>
                    </a>
                  </li>
                  <li class="list-inline-item px-2" v-if="(isFinancialOperator || isAdmin) && checkStatus(row.item.applicationStatus, [3, 5, 6])">
                    <a v-b-tooltip.hover.topleft title="Edycja wniosku">
                      <b-link :to="{ name: 'applications-details-edit', params: { uuid: row.item.applicationId } }" class="grid-action-btn">
                        <i class="font-size-14 far fa-edit text-success" />
                      </b-link>
                    </a>
                  </li>
                  <!-- <li class="list-inline-item px-2" v-if="(isFinancialOperator || isAdmin) && row.item.countAttachments > 0">
                    <a v-b-tooltip.hover.topleft title="Dodano załączniki">
                        <i class="font-size-14 fas fa-user-check text-warning" />
                    </a>
                  </li> -->
                </ul>

                <!-- <span style="padding-top: 2rem; padding-bottom: 2rem; margin: -2.75rem -0.75rem !important; display:block;" @click="onActionClick(row)">
                  <div class="d-flex">
                    <b-link class="p-0 m-0 pt-1 mx-1" v-b-tooltip.hover.topleft title="Szczegóły wniosku" :to="{ name: 'applications-details', params: { uuid: row.item.applicationId } }">
                      <i class="font-size-14 far fa-file-alt text-info" />
                    </b-link>
                    <b-link class="p-0 m-0 pt-1 mx-1" v-if="row.item.applicationStatus != 'Potwierdzone przez wnioskującego'" v-b-tooltip.hover.topleft title="Edycja wniosku" :to="{ name: 'applications-details-edit', params: { uuid: row.item.applicationId } }">
                      <i class="font-size-14 far fa-edit text-info" />
                    </b-link>
                    <b-link class="p-0 m-0 pt-1 mx-1" v-if="row.item.applicationStatus != 'Potwierdzone przez wnioskującego'" v-b-tooltip.hover.topleft title="Zmień status wniosku" :to="{ name: 'applications-details-change-status', params: { uuid: row.item.applicationId } }">
                      <i class="font-size-14 fas fa-sync text-info" />
                    </b-link>
                  </div>
                </span> -->
              </template>
            </b-table>

            <!-- Pagination -->
            <div class="row">
              <div class="col">
                <template v-if="grid.totalRows === 0">
                  <div class="d-flex justify-content-center mt-3">
                    <b>Nie znaleziono elementów</b>
                  </div>
                </template>
                <template v-if="grid.totalRows > 0">
                  <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                    Znaleziono {{ grid.totalRows | wniosek }}.<br/>
                    Zaznaczono {{ totalSelectedRows | wniosek }} w tym {{ totalSelectedOnPage }} na bieżącej stronie na łączną kwotę {{ totalRowsGrossAmount | currencyFilter }}.
                  </div>
                </template>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-if="grid.totalRows > 0" v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="filters.grid.perPage" first-number last-number />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!isAdmin && !isSuperAccepting && !isFinancialOperator && !isProductAdministrator">
      <div class="col-12">
        <b-alert variant="danger" class="mb-3" show fade>
          <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Strona, której szukasz nie została odnaleziona lub nie masz uprawnień do tego zasobu. Sprawdź poprawność adresu lub skontaktuj się z administratorem.
        </b-alert>
      </div>
    </div>


    <!-- **************************************************************************** -->
    <!-- drawer for account change -->

    <el-drawer title="Grupowa zmiana kont oraz dat operacji wniosków" direction="btt" :visible.sync="drawer" size="75%">
      <div class="container-fluid pb-5" @submit.prevent="formSubmitMultiAccount">
        <div class="row" v-for="(item, index) in selectedItems" :key="index">
          <div class="col-md-2">
            <strong>{{ item.applicationNumber }}</strong><br/>
            <span class="text-nowrap">
              {{ item.typeOfApplication }}
              <template v-if="item.typeOfApplication == 'Przelew krajowy'">
                <br />
                <small class="text-muted">{{ item.typeOfTransfer }}</small>
              </template>
              <template v-if="item.typeOfApplication == 'Przelew skarbowy'">
                <br />
                <small class="text-muted">{{ item.applicationSpecificData.isRecurring ? 'Cykliczny' : 'Jednorazowy' }}</small>
              </template>
              <template v-if="item.typeOfApplication == 'Zasilenie karty'">
                <br />
                <small class="text-muted">{{ item.applicationSpecificData.prepaidCardSettlement.isChecked ? 'Rozliczenie sprawdzone' : 'Rozliczenie niesprawdzone' }}</small>
              </template>
            </span>
          </div>
          <div class="col-md-10" v-if="(isFinancialOperator || isAdmin) && checkStatus(item.applicationStatus, [3, 5, 6])">
            <div class="row">
              <div class="col-md-6">
                <AccountNumber
                @account-number="getAccountNumber"
                :index="index"
                :application="item"
                :key="componentKey"
                :incomingAccountNumber="detailsEditForm.accountNumber[index]"
                name="AccountNumber" ref="AccountNumber" />
                <DateOfOperation
                @date-of-operation="getDateOfOperation"
                :index="index"
                :application="item"
                :key="componentKey"
                :incomingDateOfOperation="detailsEditForm.dateOfOperation[index]"
                name="DateOfOperation" ref="DateOfOperation" />
              </div>
              <div class="col-md-6">
                <Dropzone
                @attachments="getAttachments"
                :index="index"
                :application="item"
                :key="componentKey"
                name="Dropzone" ref="Dropzone" />
              </div>
            </div>
          </div>
          <div v-else class="col-md-9">
            <b-alert variant="danger" class="mb-1" show fade>
              <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Nie posiadasz uprawnień do modyfikacji tego zasobu lub wniosek jest w nieodpowiednim statusie.
            </b-alert>
          </div>
          <div class="col-md-12">
            <hr/>
          </div>
        </div>
        <div class="row" v-if="(isFinancialOperator || isAdmin) && hasCorrectFields && selectedItems.length > 0">
          <div class="col-md-4 offset-md-4">
            <b-button block :disabled="disabledBtn" variant="success" v-on:click="onCompleteMultiAccount">Aktualizuj wnioski</b-button>
          </div>
        </div>
        <div class="row" v-if="selectedItems.length == 0">
          <div class="col-md-12">
            <b-alert variant="danger" class="mb-1" show fade>
              <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Nie wybrano wniosków.
            </b-alert>
          </div>
        </div>
      </div>
    </el-drawer>

  </Layout>
</template>