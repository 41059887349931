<script>
import { required } from 'vuelidate/lib/validators'
import vue2Dropzone from "vue2-dropzone";
import Client from "@/app/repository/axios-client";
import Repository from "@/app/repository/repository-factory";

const PaymentApplicationAttachmentRepository = Repository.get(
  "PaymentApplicationAttachmentRepository"
);

export default {
  name: 'DropzoneComponent',
  components: { vueDropzone: vue2Dropzone, },
  props: {
    index: {
      type: Number,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attachmentsList: [],
      inputLoading: false,
      dropzoneOptions: {
        url: PaymentApplicationAttachmentRepository.getResourcePath(),
        thumbnailWidth: 150,
        paramName: "file",
        maxFilesize: 10, // 10MB
        addRemoveLinks: true,
        dictRemoveFile: "✕",
        headers: {
          Authorization: Client.defaults.headers.common["Authorization"],
          "Cache-Control": null,
          "X-Requested-With": null,
        },
      },

    }
  },
  validations: {
    attachmentsList: [],
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.inputLoading = true;
    },
    dropzoneAfterSuccess(file, response) {
      this.attachmentsList.push(response.uuid);
      file["tempUploadUuid"] = response.uuid;
    },
    dropzoneAfterRemove(file, error, xhr) {
      for (let i = 0; i < this.attachmentsList.length; i++) {
        if (!this.attachmentsList.attachmentId) {
          if (
            file.tempUploadUuid &&
            this.attachmentsList[i] === file.tempUploadUuid
          ) {
            this.attachmentsList.splice(i, 1);
          }
        }
      }
    },
    dropzoneSendingEvent(file, xhr, formData) {
      formData.append("type", 2);
    },
  },
  watch: {
    attachmentsList: function () {
      this.$emit('attachments', this.attachmentsList, this.index)
    },
  },
}
</script>

<template>
  <div class="form-group">
    <label for="attachmentsList">Załączniki</label>
    <vue-dropzone
     id="attachmentsList" :options="dropzoneOptions" v-model="attachmentsList"
     @input="$v.attachmentsList.$touch()" @vdropzone-success="dropzoneAfterSuccess" @vdropzone-sending="dropzoneSendingEvent" @vdropzone-removed-file="dropzoneAfterRemove" :useCustomSlot=true
     >
      <div class="dropzone-custom-content dz-message">
          upuść pliki tutaj lub kliknij, aby przesłać
      </div>
    </vue-dropzone>
  </div>
</template>
